.navbar {
  background-color: var(--navbar-background-color);
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.navbar li {
  padding: 0;
  display: flex;
  list-style-type: none;
  /* width: 8vw; */
  justify-content: center;
  align-items: center;
  /* padding: 0.5%;
  margin: 0.8%; */
}

.navbar a {
  text-decoration: none;
  color: var(--navbarA-color);
  font-size: calc(9px + 0.9vmin);
  font-weight: bolder;
}

.navbar a:hover {
  color: var(--navbarAHover-color);
}

/* :focus is not working */
/* .navbar a:focus {
  color: var(--navbarAHover-color);
} */

.navbar a:active {
  color: var(--navbarA-color);
}

.navbar li:active {
  background-color: var(--navbarLiHover-color);
}

:target {
  border: 2px solid var(--targetBorder-color);
  background-color: var(--targetBackground-color);
  transition: background-color 1900ms ease-in-out;
}

.darkmode-button {
  display: contents;
  cursor: pointer;
}

.darkmode-icon {
  width: 2%;
  background-color: var(--dark-mode-button-background-color);
  border-radius: 35%;
  padding: 0.5%;
  margin: 0.8%;
}

@media (max-width: 414px) {
  .navbar ul {
    justify-content: space-between;
    padding: 2%;
  }

  .navbar li {
    margin: -2% 0;
  }

  .darkmode-icon {
    width: 5%;
    padding: 1%;
  }
}
