.skills-container {
  display: flex;
  flex-wrap: wrap;
}

.single-skills-container {
  margin: 0.8%;
  flex: 1 1 150px;
  border: 3px solid var(--cardBorder-color);
  background-color: rgba(255, 255, 255, 0.123);
  box-shadow: 5px 3px 3px var(--cardShadow-color);
}

.container-title {
  text-align: center;
}

.single-skills-container-ul {
  background-color: var(--cardBackground-color);
  text-align: center;
}

li {
  list-style-type: none;
  color: var(--navbar-background-color);
}
