.contact-information-container ul {
  display: flex;
  justify-content: space-around;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-link-container {
  display: contents;
  color: var(--text-color);
}

.icon-link-container:hover {
  color: var(--text-color);
}

.icon-link-container:active {
  color: var(--text-color);
}

.icon-link-container:visited {
  color: var(--text-color);
}
