* {
  /* border: solid 0.1px black; */
  padding: 1%;
  font-size: calc(9px + 1vmin);
  scroll-behavior: smooth;
  font-family: 'Source Code Pro', monospace;
}

html,
body,
#root {
  padding: 0;
}

#root {
  display: flex;
  justify-content: center;
  padding: 1% 0;
}

.App {
  width: clamp(350px, 99%, 1200px);
  color: var(--text-color);
  background-color: var(--background-color);
  border: 5px solid var(--border-color);
  padding: 0;
}

/* Typing animation */
.typing-div {
  display: inline-table;
}

.typing-div h1 {
  overflow: hidden;
  border-right: calc(1px + 0.8vmin) solid var(--text-color);
  letter-spacing: calc(1px + 0.8vmin);
  animation: typing 1100ms steps(7, end), blink-cursor 750ms step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-cursor {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--text-color);
  }
}

.header h1,
.header p {
  font-family: 'Press Start 2P', cursive;
  line-height: 1.6;
}

.header h1 {
  font-size: calc(10px + 5vmin);
  padding-left: 2%;
}

.header p {
  font-size: calc(10px + 2vmin);
  text-align: right;
}

.header p,
.main p {
  padding: 1%;
}

.profile-picture-div {
  display: flex;
  justify-content: center;
}

.about p::first-letter {
  font-weight: bolder;
  font-size: calc(10px + 2vmin);
  /* float: left; */
  /* line-height: 95%; */
  /* margin-top: 1%; */
  /* margin-bottom: -1%; */
  margin-left: 2%;
  /* margin-right: 3%; */
  /* padding: 1%; */
  /* border: 1px solid black; */
}

.resume-information-container ul {
  display: flex;
}

.resume-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 0 1 22%; */
  flex-basis: 22%;
}

.resume-icon-container a {
  display: contents;
}

/* Global */
h1 {
  font-size: calc(12px + 3vmin);
  padding: 1%;
}

h2 {
  font-size: calc(10px + 2vmin);
  padding: 1%;
}

h3 {
  font-size: calc(9px + 1.5vmin);
  padding: 1%;
}
span {
  font-weight: bolder;
  font-style: italic;
  padding: 0;
}

.picture {
  border: 2px solid var(--border-color);
  filter: var(--grayscale);
  border-radius: 3%;
  padding: 0;
}

.profile-picture {
  border-radius: 25%;
}

.icon {
  filter: var(--grayscale);
}

.icon:hover {
  transform: scale(1.1);
  filter: grayscale(0%);
}

@media (max-width: 414px) {
  #root {
    padding: 0;
  }
  /* navbar media query in Navbar.css */
  h2 {
    text-align: center;
  }

  .resume-icon-container {
    justify-content: center;
    /* margin: 0; */
    flex-basis: auto;
  }

  .icon {
    width: 15%;
  }

  .contact-information-container ul {
    flex-direction: column;
  }
}

/* Light Mode */
[theme='light'] {
  --background-color: white;
  --text-color: black;
  --border-color: #333;
  --cardBorder-color: cadetblue;
  --cardBackground-color: rgba(255, 248, 220, 0.733);
  --cardShadow-color: #333;
  --navbar-background-color: #333;
  --navbarA-color: #f2f2f2;
  --navbarAHover-color: brown;
  --navbarLiHover-color: brown;
  --targetBackground-color: #e5eecc;
  --targetBorder-color: #d4d4d4;
  --grayscale: grayscale(10%);
  /* --dark-mode-button-background-color: rgba(67, 4, 126, 0.562); */
  --dark-mode-button-background-color: rgba(190, 141, 236, 0.562);
}
/* Dark Mode */
[theme='dark'] {
  --background-color: #333;
  --text-color: #f2f2f2;
  --border-color: black;
  --cardBorder-color: rgba(95, 158, 160, 0.493);
  --cardBackground-color: rgba(255, 248, 220, 0.877);
  --cardShadow-color: black;
  --navbar-background-color: black;
  --navbarA-color: white;
  --navbarAHover-color: brown;
  --navbarLiHover-color: brown;
  --targetBackground-color: #e5eecc4d;
  --targetBorder-color: #d4d4d438;
  --grayscale: grayscale(40%);
  --dark-mode-button-background-color: rgba(230, 205, 174, 0.5);
}
