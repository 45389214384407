.projects-container {
  display: flex;
  flex-wrap: wrap;
}

.single-project-container {
  margin: 0.8%;
  flex: 1 1 250px;
  /* flex: 1 1 29%; */
  border: 3px solid var(--cardBorder-color);
  background-color: rgba(255, 255, 255, 0.123);
  box-shadow: 5px 3px 3px var(--cardShadow-color);
  /* display: grid; */
  /* grid-template-rows: 1fr 2fr 1fr 3fr; */
}

.project-title {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
}

.single-project-container p::first-letter {
  padding-left: 2%;
}

.single-project-container a {
  display: contents;
}

.screenshot:hover {
  transform: scale(1.01);
  filter: grayscale(0%);
}

.one-git-repo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.two-git-repos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}
